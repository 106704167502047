import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import CookieConsent from "react-cookie-consent";
import Lottie from "lottie-react";
import { Link, useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { useLocation } from "@reach/router";
import { motion } from "framer-motion";

//Components
import MouseScrollAnimated from "../components/MouseScrollAnimated";
import HeroGradient from "../components/HeroGradient";
import YellowSection from "../components/YellowSection";
import SandboxHeader from "./sandbox/Header";
import Accordion from "../components/Accordion";
import Footer from "../components/Footer";

//Assets
import eatingCookieAnimationData from "../images/lottie/eating-cookie.json";
import loyalty from "../images/pos/journyIcons/loyalty.svg";
import transactions from "../images/pos/journyIcons/transactions.svg";
import basket from "../images/pos/journyIcons/basket.svg";
import rewards from "../images/pos/journyIcons/rewards.svg";
import vas from "../images/pos/journyIcons/vas.svg";
import terminals from "../images/pos/journyIcons/terminals.svg";
import retail from "../images/pos/journyIcons/retail.svg";
import interchange from "../images/pos/journyIcons/interchange.svg";
import ArrowAltRightIcon from "../images/icons/arrow-alt-right.svg";
import network from "../images/partners/network-logo.svg";
import alamin from "../images/partners/alamin-logo.png";
import machine from "../images/pos/journyIcons/machine.png";

//Styles
import * as styles from "../styles/pages/sandbox.module.css";

// Utils
import {
  revealContainerProps,
  revealContainerWithChildrenProps,
  revealVariant,
} from "../utils/animation-variants";
import clsx from "clsx";

const MotionLink = motion(Link);

const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <section>
      <div className={styles.heroSection__wrapper}>
        <HeroGradient />
        <motion.div
          {...revealContainerWithChildrenProps}
          className={`${styles.heroSection__content} sectionContainer`}
        >
          <motion.div variants={revealVariant}>
            <h2 className={styles.heroSection__intro}>
              {t("sandbox.heroSection.intro")}
            </h2>
            <h1
              className={styles.heroSection__title}
              dangerouslySetInnerHTML={{
                __html: t("sandbox.heroSection.title", {
                  highlight: `<span>${t(
                    "sandbox.heroSection.title.highlight"
                  )}</span>`,
                }),
              }}
            />
            <p className={styles.heroSection__description}>
              {t("sandbox.heroSection.description")}
            </p>
            <div className={styles.heroSection__button__wrapper}>
              <Link
                to="/sandbox/signup"
                className={`${styles.heroSection__button} button button--primary`}
              >
                {t("sandbox.heroSection.button")}
              </Link>
            </div>
            <MouseScrollAnimated />
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

const BenefitsSection = () => {
  const { t } = useTranslation();
  return (
    <section>
      <motion.div className={styles.benefitsSection__container}>
        <motion.div
          variants={revealVariant}
          className={styles.heroSection__hero_img_desktop}
        >
          <StaticImage
            layout="fullWidth"
            alt="Zeal Pay"
            src="../images/sandbox-hero.png"
            formats={["auto", "webp", "avif"]}
            className={styles.heroSection__backgroundImg}
          />
          <div className={styles.heroSection__hero_img__notification}>
            <StaticImage
              className="elevetion-animation"
              src={"../images/homepage-hero-notification.svg"}
              alt="Your rewards is available 😍🚀"
            />
          </div>
        </motion.div>
        <motion.div className={styles.benefitsSection__wrapper}>
          <h1 className={styles.benefitsSection__title}>
            {t("sandbox.benefitsSection.title")}
          </h1>
          <h2 className={styles.benefitsSection__description}>
            {t("sandbox.benefitsSection.description")}
          </h2>
        </motion.div>
      </motion.div>
    </section>
  );
};

const POSJourneySection = () => {
  const { t } = useTranslation();
  const posFeatures = [
    {
      id: "loyalty",
      image: loyalty,
      content: t("pos.journey.loyalty"),
    },
    {
      id: "transactions",
      image: transactions,
      content: t("pos.journey.transactions"),
    },
    {
      id: "basket",
      image: basket,
      content: t("pos.journey.basket"),
    },
    {
      id: "rewards",
      image: rewards,
      content: t("pos.journey.rewards"),
    },
    {
      id: "vas",
      image: vas,
      content: t("pos.journey.vas"),
    },
    {
      id: "terminals",
      image: terminals,
      content: t("pos.journey.terminal"),
    },
    {
      id: "retail",
      image: retail,
      content: t("pos.journey.retailers"),
    },
    {
      id: "interchange",
      image: interchange,
      content: t("pos.journey.interchange"),
    },
  ];

  return (
    <section>
      <motion.div
        {...revealContainerWithChildrenProps}
        className={`${styles.posJourney_container} sectionContainer`}
      >
        <motion.div className={styles.posJourney__content}>
          <motion.h1
            {...revealContainerProps}
            variants={revealVariant}
            className={styles.posJourney__header}
          >
            {t("pos.journey.title")}
          </motion.h1>
          <motion.img src={machine} className={styles.posJourney__img} />
        </motion.div>
        <motion.div
          {...revealContainerWithChildrenProps}
          className={clsx([
            styles.posJourney__features,
            "grid-cols-1 sm:!grid-cols-2",
          ])}
        >
          {posFeatures.map((feat) => (
            <motion.div key={feat.id} className={styles.posJourney__item}>
              <motion.img src={feat.image} />
              <motion.div>
                <motion.p className={styles.posJourney__itemContent}>
                  {feat.content}
                </motion.p>
              </motion.div>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </section>
  );
};

const POSTerminalSection = () => {
  const { t } = useTranslation();
  return (
    <section className={`${styles.POSSection} sectionContainer`}>
      <div className={styles.POSSection__card}>
        <motion.div
          {...revealVariant}
          variants={revealVariant}
          className={styles.POSSection__image}
        >
          <StaticImage
            className="elevetion-animation"
            src={"../images/homepage-section-4.png"}
            alt="& POS terminal"
          />
        </motion.div>
        <motion.div {...revealContainerWithChildrenProps}>
          <motion.h2
            variants={revealVariant}
            className={styles.POSSection__title}
          >
            {t("sandbox.terminalSection.title")}
          </motion.h2>
          <motion.p
            variants={revealVariant}
            className={styles.POSSection__description}
          >
            {t("sandbox.terminalSection.description")}
          </motion.p>
          <MotionLink
            to="/sandbox/signup"
            variants={revealVariant}
            className={`button with-icon button--primary ${styles.POSSection__button}`}
          >
            {t("sandbox.heroSection.button")}
            <span>
              <ArrowAltRightIcon className="arrow-right" />
            </span>
          </MotionLink>
        </motion.div>
      </div>
    </section>
  );
};

const OurPartnersSection = () => {
  const { t } = useTranslation();

  const partners = [
    {
      id: "network",
      image: network,
    },
    {
      id: "alamin",
      image: alamin,
    },
  ];
  return (
    <section className={`sectionContainer`}>
      <motion.div
        {...revealContainerWithChildrenProps}
        className={`${styles.benefitsSection__wrapper} !m-0 !mb-[9.5rem]`}
      >
        <motion.h2
          variants={revealVariant}
          className={styles.partnerSection__title}
        >
          {t("sandbox.partnerSection.title")}
        </motion.h2>
        <motion.p
          variants={revealVariant}
          className={styles.partnerSection__description}
        >
          {t("sandbox.partnerSection.description")}
        </motion.p>
        <motion.div
          variants={revealVariant}
          className="flex items-center justify-center !gap-14 flex-col lg:flex-row"
        >
          {partners.map((partner) => (
            <motion.img
              className={styles.partnerSection__img}
              variants={revealVariant}
              src={partner.image}
              alt={partner.id}
              key={partner.id}
            />
          ))}
        </motion.div>
      </motion.div>
    </section>
  );
};

const FAQsSection = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const faqs = [
    {
      id: "1",
      title: t("sandbox.faqSection.quest1"),
      content: t("sandbox.faqSection.answer1"),
    },
    {
      id: "2",
      title: t("sandbox.faqSection.quest2"),
      content: t("sandbox.faqSection.answer2"),
    },
    {
      id: "3",
      title: t("sandbox.faqSection.quest3"),
      content: t("sandbox.faqSection.answer3"),
    },
    {
      id: "4",
      title: t("sandbox.faqSection.quest4"),
      content: t("sandbox.faqSection.answer4"),
    },
    {
      id: "5",
      title: t("sandbox.faqSection.quest5"),
      content: t("sandbox.faqSection.answer5"),
    },
    {
      id: "6",
      title: t("sandbox.faqSection.quest6"),
      content: t("sandbox.faqSection.answer6"),
    },
    {
      id: "7",
      title: t("sandbox.faqSection.quest7"),
      content: t("sandbox.faqSection.answer7"),
    },
    {
      id: "8",
      title: t("sandbox.faqSection.quest8"),
      content: t("sandbox.faqSection.answer8"),
    },
    {
      id: "9",
      title: t("sandbox.faqSection.quest9"),
      content: t("sandbox.faqSection.answer9"),
    },
  ];

  return (
    <section className={`sectionContainer`}>
      <motion.div
        {...revealContainerWithChildrenProps}
        className={`${styles.benefitsSection__wrapper} !m-0 !mt-[8.125rem] !mb-[6.625rem]`}
      >
        <motion.h2
          variants={revealVariant}
          className={styles.partnerSection__title}
        >
          {t("sandbox.faqSection.title")}
        </motion.h2>
        <motion.p
          variants={revealVariant}
          className={`${styles.faqs__description}`}
        >
          {t("sandbox.faqSection.description")}
        </motion.p>
        <motion.div>
          <Accordion items={faqs} />
        </motion.div>
        <MotionLink
          to="/contact-us"
          state={{ referrer: location.href }}
          variants={revealVariant}
          className={`${styles.faqs__button} button button--secondary !mt-8`}
        >
          {t("sandbox.supportButton")}
        </MotionLink>
      </motion.div>
    </section>
  );
};

const Sandbox = ({ hasNavbarOnlyWhite }) => {
  const { t } = useTranslation();
  return (
    <div className="layout">
      <SandboxHeader isOnlyWhite={hasNavbarOnlyWhite} />
      <main>
        <HeroSection />
        <BenefitsSection />
        <POSJourneySection />
        <POSTerminalSection />
        <OurPartnersSection />
        <YellowSection
          title={t("sandbox.retainingSection.title")}
          description={t("sandbox.retainingSection.description")}
          to="/sandbox/signup"
          btnText={t("sandbox.getStartedButton")}
        />
        <FAQsSection />
      </main>
      <Footer />
      <CookieConsent
        expires={150}
        enableDeclineButton
        disableStyles
        buttonWrapperClasses="buttons-wrapper"
        buttonText={t("cookie.button.accept")}
        declineButtonText={t("cookie.button.decline")}
      >
        <Lottie animationData={eatingCookieAnimationData} />
        <strong>{t("cookie.title")}</strong>
        <p>
          <Trans
            i18nKey="cookie.description"
            values={{ link: t("cookie.description.link") }}
            components={[<Link to="/term-and-conditions" />]}
          />
        </p>
      </CookieConsent>
    </div>
  );
};

export default Sandbox;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "index", "sandbox"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
