// extracted by mini-css-extract-plugin
export var POSSection = "sandbox-module--POSSection--f048c";
export var POSSection__button = "sandbox-module--POSSection__button--31e55";
export var POSSection__card = "sandbox-module--POSSection__card--e7af4";
export var POSSection__description = "sandbox-module--POSSection__description--0ef2b";
export var POSSection__image = "sandbox-module--POSSection__image--b57c3";
export var POSSection__title = "sandbox-module--POSSection__title--0cca4";
export var benefitsSection__container = "sandbox-module--benefitsSection__container--0bce4";
export var benefitsSection__description = "sandbox-module--benefitsSection__description--bdffc";
export var benefitsSection__title = "sandbox-module--benefitsSection__title--0dc17";
export var benefitsSection__wrapper = "sandbox-module--benefitsSection__wrapper--fc90d";
export var faqs__button = "sandbox-module--faqs__button--a58d1";
export var faqs__description = "sandbox-module--faqs__description--651b5";
export var heroSection__backgroundImg = "sandbox-module--heroSection__backgroundImg--f8d4c";
export var heroSection__button = "sandbox-module--heroSection__button--8ae93";
export var heroSection__button__wrapper = "sandbox-module--heroSection__button__wrapper--e0cf9";
export var heroSection__content = "sandbox-module--heroSection__content--dafb3";
export var heroSection__description = "sandbox-module--heroSection__description--05459";
export var heroSection__hero_img__notification = "sandbox-module--heroSection__hero_img__notification--e2bd5";
export var heroSection__hero_img_desktop = "sandbox-module--heroSection__hero_img_desktop--7c838";
export var heroSection__intro = "sandbox-module--heroSection__intro--8ec71";
export var heroSection__title = "sandbox-module--heroSection__title--41bc1";
export var heroSection__wrapper = "sandbox-module--heroSection__wrapper--6d00c";
export var partnerSection__description = "sandbox-module--partnerSection__description--7869d";
export var partnerSection__img = "sandbox-module--partnerSection__img--d77cc";
export var partnerSection__title = "sandbox-module--partnerSection__title--6b73e";
export var posJourney__content = "sandbox-module--posJourney__content--b92a8";
export var posJourney__features = "sandbox-module--posJourney__features--42720";
export var posJourney__header = "sandbox-module--posJourney__header--2e6d3";
export var posJourney__img = "sandbox-module--posJourney__img--fb5f2";
export var posJourney__item = "sandbox-module--posJourney__item--94382";
export var posJourney__itemContent = "sandbox-module--posJourney__itemContent--e74d1";
export var posJourney_container = "sandbox-module--posJourney_container--d7c0a";