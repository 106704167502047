import React, { useState } from "react";

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="sectionContainer">
      {items.map((item, index) => (
        <div
          key={item.id}
          className="!p-6 bg-white rounded-2xl !mb-6 sectionContainer"
        >
          <div>
            <button
              className="flex justify-between w-full text-[22px] font-normal text-left text-black lg:gap-[23rem]"
              onClick={() => handleClick(index)}
            >
              <span>{item.title}</span>
              <svg
                className={`w-[1.875rem] h-[1.875rem] transform ${
                  activeIndex === index ? "rotate-180" : ""
                }`}
                viewBox="0 0 20 20"
                fill="#00DFAC"
              >
                <path
                  fillRule="evenodd"
                  d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <div
              className={`${
                activeIndex === index ? "block mt-3" : "hidden"
              } text-gray-500 text-lg font-normal `}
            >
              {item.content}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
